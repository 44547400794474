import { useEffect, useState } from 'react'
import {useAuth} from './../auth'
import {getCurrentCarousel} from './../api'

const useCurrentCarousel = (id) => {

  const [currentCarousel, setCurrentCarousel] = useState()
  const auth = useAuth()

  const getData = async(setFunc) => {
    try{
        const response = await getCurrentCarousel(id)

        setFunc(response.data)
    }catch(err){
        console.log(err)
        if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
            alert('Connection Timed Out')
            auth.logout()
        } 
    }
  }

  useEffect(()=>{
    getData(setCurrentCarousel)
  }, [auth.user])

  return [currentCarousel]


}

export default useCurrentCarousel