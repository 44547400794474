import { useEffect, useState } from "react"
import { getVacancies } from "../api"
import { useAuth } from "../auth"

const useVacancies = (pageNum, update) => {
    
    const [vacancies, setVacancies] = useState()
    const auth = useAuth()

    const getData = async(setFunc) => {
        try{
        const response = await getVacancies(pageNum)

        setFunc(response.data)
        }catch(err){
            console.log(err)
            if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
                alert('Connection Timed Out')
                auth.logout()
            } 
        }
    }

    useEffect(()=>{
        getData(setVacancies) 

    }, [auth.user, update])
 
    return [vacancies]  

}

export default useVacancies