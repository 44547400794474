import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './css.css'
import useSingleService from './../../../utils/hooks/useSingleService'
import {ArrowBack} from './../../../assets/icons'
import { addSubImage, addSubItem, addSubVideo, deleteServiceImage, deleteSubItem, deleteSubVideo, editService } from '../../../utils/api'
import { useRecoilState } from 'recoil'
import {baseUrlState} from './../../../utils/atoms'
import Modal from '../../Modal'
import { toast } from 'react-toastify'


const ServicesEdit = () => {
    const {serviceId} = useParams()
    const [url] = useRecoilState(baseUrlState)
    const [update, setUpdate] = useState(false)
    const [currentServices] = useSingleService(serviceId, update)
    const [isActive, setIsActive] = useState(false)
    const [contenFormat, setContentFormat] = useState()
    const navigate = useNavigate()

    const [subItemTitle, setSubItemTitle] = useState()
    const [subImage, setSubImage] = useState()
    const [subVideo, setSubVideo] = useState()

    const [title, setTitle] = useState()
    const [description, setDescription] = useState()
    const [isEnabled, setIsEnabled] = useState()

    const handleDeleteSubItem = async(id) => {
        await deleteSubItem(serviceId, id)
        setUpdate(!update)

    }

    const handleDeleteImage = async(id) => {
        await deleteServiceImage(serviceId, id)
        setUpdate(!update)
    }

    const handleDeleteVideo = async(id) => {
        await deleteSubVideo(serviceId, id)
        setUpdate(!update)
    }

    const renderItems = useMemo(()=>{
        return currentServices && currentServices.service.service_item_translations.map((elem, i)=>{
            return(
                <li key={i}>
                    {elem.title}
                    <div className='editButtons'>
                        <button className='displayNone'></button>
                        <button onClick={()=>{handleDeleteSubItem(elem.id)}}>X</button>
                    </div>
                </li>  
            )
        }) 
    }, [currentServices, update])

    const renderImages = useMemo(()=>{
        return currentServices && currentServices.service.service_images.map((elem, i)=>{
            return(
                <div key={i} className='Panel'>
                    <img src={url + elem.image}/>
                    <div className='servicePanelControls'>
                        <button onClick={()=>{handleDeleteImage(elem.id)}}>Delete</button>
                    </div> 
                </div>
            )
        }) 
    }, [currentServices, update])

    const renderVideos = useMemo(()=>{
        return currentServices && currentServices.service.service_videos.map((elem, i)=>{
            return(
                <div key={i} className='Panel'>
                        <video autoPlay controls muted>
                            <source src={url + elem.video}/>
                        </video>
                        <div className='servicePanelControls'>
                            <button onClick={()=>{handleDeleteVideo(elem.id)}}>Delete</button>
                        </div> 
                </div>
            )
        })
    }, [currentServices, update])

    const addItem = async() => {
        try{
        const response = await addSubItem(serviceId, subItemTitle && new URLSearchParams({
            "new_subitem_title": subItemTitle
        }))
        console.log(response)
        if(response.status === 201){
            toast.success('Created successfuly', {
                position: "bottom-center",                                
                closeOnClick: true,                                
                progress: undefined,
                theme: "colored",
                });
            setIsActive(false)
            setUpdate(!update)
        }else{
            toast.error('Something went wrong', {
                position: "bottom-center",                                
                closeOnClick: true,                                
                progress: undefined,
                theme: "colored",
                }); 
        }

        

        }catch(err){
            console.log(err)
            toast.error('Something went wrong', {
                position: "bottom-center",                                
                closeOnClick: true,                                
                progress: undefined,
                theme: "colored",
                }); 
        } 
    }
    
    const addImage = async() => {
        try{
            const fd = new FormData()
            subImage && fd.append("item_images", subImage)

            const response = await addSubImage(serviceId, fd)
            console.log(response)

            if(response.status === 201){
                toast.success('Created successfuly', {
                    position: "bottom-center",                                
                    closeOnClick: true,                                
                    progress: undefined,
                    theme: "colored",
                    });
                setIsActive(false)
                setUpdate(!update)   

            }else{
                toast.error('Something went wrong', {
                    position: "bottom-center",                                
                    closeOnClick: true,                                
                    progress: undefined,
                    theme: "colored",
                    }); 
            }

        }catch(err){
            console.log(err)
            toast.error('Something went wrong', {
                position: "bottom-center",                                
                closeOnClick: true,                                
                progress: undefined,
                theme: "colored",
                }); 
        }
    }

    const addVideo = async() => {
        try{
            const fd = new FormData()
            subVideo && fd.append('item_videos', subVideo)

            const response = await addSubVideo(serviceId, fd)
            
            if(response.status === 201){
                toast.success('Created successfuly', {
                    position: "bottom-center",                                
                    closeOnClick: true,                                
                    progress: undefined,
                    theme: "colored",
                    });
            }else{
                toast.error('Something went wrong', {
                    position: "bottom-center",                                
                    closeOnClick: true,                                
                    progress: undefined,
                    theme: "colored",
                    }); 
            }

        }catch(err){
            console.log(err)
            toast.error('Something went wrong', {
                position: "bottom-center",                                
                closeOnClick: true,                                
                progress: undefined,
                theme: "colored",
                }); 
        }
    }

    
    const handleEditService = async() => {
        try{
            const response = await editService(serviceId, new URLSearchParams({
                "title": title ? title : currentServices && currentServices.title,
                "description": description ? description : currentServices && currentServices.description,
                "is_active": isEnabled ? isEnabled : currentServices && currentServices.is_active
            }))

            if(response.status === 202){
                toast.success('Created successfuly', {
                    position: "bottom-center",                                
                    closeOnClick: true,                                
                    progress: undefined,
                    theme: "colored",
                    });
            }else{
                toast.error('Something went wrong', {
                    position: "bottom-center",                                
                    closeOnClick: true,                                
                    progress: undefined,
                    theme: "colored",
                    }); 
            }

        }catch(err){
            console.log(err)
            toast.error('Something went wrong', {
                position: "bottom-center",                                
                closeOnClick: true,                                
                progress: undefined,
                theme: "colored",
                }); 
        }

    }
    

  return (
    <div className='navDebug servicesPageEdit'>
        <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
        <div className='changeNotes'>
            <div>
                <h4>State of component</h4>
                <label htmlFor="inputCheckBox">Enable/Disable </label>
                <input onChange={(e)=>{setIsEnabled(e.target.checked)}} className='changeNotesCheckbox' id="inputCheckBox" defaultChecked={currentServices && currentServices.is_active} type="checkbox"/>
            </div>
            <div>
                <h4>Title</h4>
                <input placeholder='Min length: 5' onChange={(e)=>{setTitle(e.target.value)}} defaultValue={currentServices && currentServices.title} type="text"/>
            </div>
            <div>
                <h4>Description</h4>
                <input placeholder='Min length: 10' onChange={(e)=>{setDescription(e.target.value)}} defaultValue={currentServices && currentServices.description} type="text"/>
            </div>
            <div>
                <h4>Items</h4>
                <ul>
                    {renderItems}
                    <li className='emptyPanel emptyItemPanel' onClick={()=>{
                        setContentFormat('items')
                        setIsActive(true)}}><span>+</span></li>
                </ul>
            </div>
            <div>
                <h4>Images</h4>
                <div className='panelHolder'>
                    {renderImages}
                    <div className='Panel emptyPanel' onClick={()=>{
                        setContentFormat('images')
                        setIsActive(true)
                    }}>
                        <span>+</span>
                    </div>
                </div>
            </div>
            <div>
                <h4>Videos</h4>
                <div className='panelHolder'>
                    {renderVideos}
                    <div className='Panel emptyPanel' onClick={()=>{
                        setContentFormat('videos')
                        setIsActive(true)
                    }}>
                        <span>+</span>
                    </div>
                </div>
            </div>
            <button onClick={()=>{handleEditService()}}>Edit</button>
        </div>


        <Modal  content={
            <div className={`changeNotesModal ${isActive ? "" : "displayNone"}`}>
                <div className={`modal ${isActive ? "" :  "displayNone"}`}>
                    <h1>Create {contenFormat}</h1>
                 
                   {
                   
                    contenFormat === "items" ? 
                        // Items
                        <>
                            <div>
                                <h4>Title</h4>
                                <input placeholder='Minimal size 10' onChange={(e)=>{setSubItemTitle(e.target.value)}} type="text"/>
                            </div>
                            <button onClick={()=>{addItem()}}>Save</button>
                        </> : contenFormat === "images" ? 

                        // Images
                        <>
                            <div>
                                <h4>Image</h4>
                                <input onChange={(e)=>{
                                    setSubImage(e.target.files[0])
                                }} type="file"/>
                            </div>
                            <button onClick={()=>{addImage()}}>Save</button>
                        </> : contenFormat === "videos" ? 

                        // Videos
                        <>
                            <div>
                                <h4>Video</h4>
                                <input onChange={(e)=>{
                                   setSubVideo(e.target.files[0]) 
                                }} type="file"/>
                            </div>
                            <button onClick={()=>{addVideo()}}>Save</button>
                        </> :

                        // Error
                        <>
                            <div align="center"><h1>Error</h1></div>
                        </>
                    }
                </div>
                <div onClick={()=>{setIsActive(false)}} className={`changeNotesModalAlpha ${ isActive ? "" : 'displayNone'}`}/>
            </div>

        } />
    </div>
  )
}

export default ServicesEdit