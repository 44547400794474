import { useEffect, useState } from "react"
import {getPartners} from './../api'
import {useAuth} from './../auth'
 
const usePartners = (upd) => {
    
    const [partners, setPartners] = useState(undefined)
    const auth = useAuth()

    const getData = async(setFunc) => {
        try{
            const response = await getPartners()        

            setFunc(response.data)
        }catch(err){
            console.log(err)
            if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
                alert('Connection Timed Out')
                auth.logout()
            } 
        }
    }

    useEffect(()=>{
        getData(setPartners)
    }, [auth.user, upd])

    return [partners]
}

export default usePartners