import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import About from "./containers/About";
import Business from "./containers/Business";
import HomePage from "./containers/HomePage";
import Login from "./containers/Login";
import Navbar from "./layouts/Navbar";
import { AuthProvider } from "./utils/auth";
import {RequireAuth} from './utils/auth/RequireAuth'
import 'react-toastify/dist/ReactToastify.css';

import CarouselEdit from "./components/Carousel/edit";
import CarouselAdd from "./components/Carousel/add";
import NewsEdit from "./components/News/edit";
import NewsAdd from "./components/News/add";
import PartnersAdd from "./components/Partners/add";
import PartnersEdit from "./components/Partners/edit";
import CardAdd from "./components/Cards/add";
import CardEdit from "./components/Cards/edit";
import SectorAdd from "./components/Sectors/add";
import SectorEdit from "./components/Sectors/edit";
import SolutionAdd from "./components/Solutions/add";
import SolutionEdit from "./components/Solutions/edit";
import Connect from "./containers/Connect";
import VacancyAdd from "./components/Vacancy/add";
import VacancyEdit from "./components/Vacancy/edit";
import Files from "./containers/Files";
import BrochuresAdd from "./components/Brochures/add";
import ServicesAdd from "./components/Services/add";
import ServicesEdit from "./components/Services/edit";
import Candidates from "./containers/Candidates";

function App() {  
  return (
    <>
    <ToastContainer position="bottom-center"
                      autoClose={4000}
                      hideProgressBar={true}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}                                                                  
                      theme="colored" />
      <BrowserRouter>   
        <AuthProvider> 
            <Navbar />     
            <Routes>
              {/* Pages */}
              <Route index exact path="/" element={<RequireAuth> <HomePage /> </RequireAuth>} />
              <Route exact path="/admin/About" element={<RequireAuth> <About /> </RequireAuth>} />  
              <Route exact path="/admin/Business" element={<RequireAuth> <Business /> </RequireAuth>}/>  
              <Route exact path="/admin/Connect" element={<RequireAuth> <Connect /> </RequireAuth>}/>  
              <Route exact path="/admin/Files" element={<RequireAuth> <Files /> </RequireAuth>}/>  
              
              {/* Carousel */}
              <Route exact path="/admin/carousel/:itemId/edit" element={<RequireAuth> <CarouselEdit /> </RequireAuth>}/>  
              <Route exact path="/admin/carousel/add" element={<RequireAuth> <CarouselAdd /> </RequireAuth>}/>  
              
              {/* News */}
              <Route exact path="/admin/news/:itemId/edit" element={<RequireAuth> <NewsEdit /> </RequireAuth>}/>  
              <Route exact path="/admin/news/add" element={<RequireAuth> <NewsAdd /> </RequireAuth>}/>  

              {/* Partners */}
              <Route exact path="/admin/partners/add" element={<RequireAuth> <PartnersAdd /> </RequireAuth>}/>  
              <Route exact path="/admin/partners/:itemId/edit" element={<RequireAuth> <PartnersEdit /> </RequireAuth>}/>  

              {/* Cards */}
              <Route exact path="/admin/cards/add" element={<RequireAuth> <CardAdd /> </RequireAuth>}/>  
              <Route exact path="/admin/cards/:itemId/edit" element={<RequireAuth> <CardEdit /> </RequireAuth>}/>  
              
              {/* Sectors */}
              <Route exact path="/admin/sectors/add" element={<RequireAuth> <SectorAdd /> </RequireAuth>}/>  
              <Route exact path="/admin/sectors/:itemId/edit" element={<RequireAuth> <SectorEdit /> </RequireAuth>}/>  

              {/* Solutions */}
              <Route exact path="/admin/sectors/:sectorId/solution/add" element={<RequireAuth> <SolutionAdd /> </RequireAuth>}/>  
              <Route exact path="/admin/sectors/:sectorId/solution/:solutionId/edit" element={<RequireAuth> <SolutionEdit /> </RequireAuth>}/>  

              {/* Services */}
              <Route exact path="/admin/sectors/:sectorId/solution/:solutionId/service/add" element={<RequireAuth> <ServicesAdd /> </RequireAuth>}/>  
              <Route exact path="/admin/sectors/:sectorId/solution/:solutionId/service/:serviceId/edit" element={<RequireAuth> <ServicesEdit /> </RequireAuth>}/>  

              {/* Vacancies */}
              <Route exact path="/admin/vacancy/add" element={<RequireAuth> <VacancyAdd /> </RequireAuth>}/>  
              <Route exact path="/admin/vacancy/:itemId/edit" element={<RequireAuth> <VacancyEdit /> </RequireAuth>}/>  

              {/* Brochures */}
              <Route exact path="/admin/brochures/add" element={<RequireAuth> <BrochuresAdd /> </RequireAuth>}/>  

              {/* Candidates */}
              <Route exact path="/admin/candidates/:pageNum" element={<RequireAuth> <Candidates /> </RequireAuth>}/>              

              <Route exact path="/login" element={<Login />}/>
            </Routes>                               
        </AuthProvider>
      </BrowserRouter>  
    </>
  );
}

export default App;
