import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { SketchPicker } from 'react-color'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'
import { deleteSolution, editSector } from '../../../utils/api'
import { baseUrlState } from '../../../utils/atoms'
import useCurrentSector from './../../../utils/hooks/useCurrentSector'
import {ArrowBack, EditIcon} from './../../../assets/icons'
import {useNavigate} from 'react-router-dom'
import './css.css'

const SectorEdit = () => {

  const {itemId} = useParams()
  const [update, setUpdate] = useState(false)
  const [currentSector] = useCurrentSector(itemId, update)
  const [body_color, setBodyColor] = useState(undefined)
  const [accent_color, setAccentColor] = useState(undefined) 
  
  const [image, setImage] = useState()
  const [title, setTitle] = useState(undefined)
  const [description, setDescription] = useState(undefined)

  const [preview, setPreview] = useState(undefined)
  
  const [url] = useRecoilState(baseUrlState)
  const navigate = useNavigate()
  
  useEffect(()=>{
    currentSector && setBodyColor(currentSector.sector.body_color)
    currentSector && setAccentColor(currentSector.sector.accent_color)
  }, [currentSector])


  const getBodyColor = (color) => {
    setBodyColor(color.hex)
  }
  const getAccentColor = (color) => {
    setAccentColor(color.hex)
  }  

  const onSelectedFile = (e) => {
    if(!e.target.files || e.target.files.length === 0) {
      setImage(undefined)
      return
    }
    setImage(e.target.files[0])
  }

  useEffect(()=>{
    if(!image){
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(image)
    setPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl) 
  }, [image])

  
  const handleEdit = async() => {
    const form = new FormData()

    title && form.append('title', title)
    description && form.append('description', description)
    image && form.append('image', image)
    body_color && form.append('body_color', body_color)
    accent_color && form.append('accent_color', accent_color)

    try{
      const response = await editSector(itemId, form)

      if(response.status === 202){
        toast.success('Edited successfuly', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });
      }else{
        toast.error('Something went wrong', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });  
      }
    }catch(err){
      console.log(err)
      toast.error(`Something went wrong`, {
        position: "bottom-center",                                
        closeOnClick: true,                                
        progress: undefined,
        theme: "colored",
        });  
    }
  }

  const handleDelete = async(id) => {
    await deleteSolution(id)
    setUpdate(!update)
  }

  return (
    <div className='navDebug sectorEdit'>
        <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
        <p>The preview below is just a rough example of how it will look on the site.</p>
        <div className='sectorPreview' style={{backgroundColor: body_color === undefined ? currentSector && currentSector.sector.body_color : body_color, color: accent_color === undefined ? currentSector && currentSector.sector.accent_color : accent_color}}>
          <div>
            <h1>{title === undefined ? currentSector && currentSector.title : title}</h1>
            <ul>
              {currentSector && currentSector.sector.solution_translations.map((elem, i)=>{                
                return(
                  <li key={i} className="sectorEditSolutions">
                    {elem.title}
                    <div className='editButtons'>
                      <button onClick={()=>{navigate(`/admin/sectors/${itemId}/solution/${elem.solution.id}/edit`)}}><EditIcon /></button>
                      <button onClick={()=>{handleDelete(elem.solution.id)}}>X</button>
                    </div>
                  </li>
                )
              })}
              <li className='solutionCreateButton' onClick={()=>{navigate(`/admin/sectors/${itemId}/solution/add`)}}>+ Add new Solution</li>
            </ul>
            <p>{description === undefined ? currentSector && currentSector.description : description}</p>
          </div>
          <div>
            <img src={preview === undefined ? currentSector && url + currentSector.sector.image : preview} alt="img"/>
          </div>
        </div>

        <div className='changeNotes'>
          <div>
            <h4>Image</h4>
            <input type="file" onChange={(e)=>{onSelectedFile(e)}}/>
          </div>
          <div>
            <h4>Title</h4>
            <input type="text" defaultValue={currentSector && currentSector.title} onChange={(e)=>{setTitle(e.target.value)}}/>
          </div>
          <div>
            <h4>Description</h4>
            <input type="text" defaultValue={currentSector && currentSector.description} onChange={(e)=>{setDescription(e.target.value)}}/>
          </div>          
          <div>
            <h4>Body color</h4>
            <SketchPicker color={body_color} onChange={getBodyColor}/>
            <button className='dropButton' onClick={()=>{setBodyColor(currentSector && currentSector.sector.body_color)}}>Drop Color</button>
          </div>
          <div>
            <h4>Accent/Text color</h4>
            <SketchPicker color={accent_color} onChange={getAccentColor}/>
            <button className='dropButton' onClick={()=>{setAccentColor(currentSector && currentSector.sector.accent_color)}}>Drop Color</button>
          </div>
          <button onClick={handleEdit}>Edit</button>
        </div>
    </div>
  )
}

export default SectorEdit