import { useEffect } from 'react'
import { useState } from 'react'
import {getCurrentService} from './../api'
import {useAuth} from './../auth'

const useCurrentService = (id, update) => {
  
    const [currentService, setCurrentService] = useState()
    const auth = useAuth()
    
    const getData = async (setFunc) => {
        try{
            const response = await getCurrentService(id)

            setFunc(response.data)
        }catch(err){
            console.log(err)
            if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
                alert('Connection Timed Out')
                auth.logout()
            } 
        }
    }

    useEffect(()=>{
        getData(setCurrentService)
    }, [auth.user, update])

    return [currentService]

}

export default useCurrentService