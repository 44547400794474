import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { editVacancy } from '../../../utils/api'
import useCurrentVacancy from '../../../utils/hooks/useCurrentVacancy'
import './css.css'

const VacancyEdit = () => {

    const [title, setTitle] = useState()
    const [notes, setNotes] = useState()
    const [description, setDescription] = useState()

    const {itemId} = useParams()

    const [currentVacancy] = useCurrentVacancy(itemId)

    const handleEdit = async () => {
        try{
            const response = await editVacancy(itemId, new URLSearchParams({
                "title": title ? title : currentVacancy && currentVacancy.title,
                "notes": notes ? notes : currentVacancy && currentVacancy.notes,
                "description": description ? description : currentVacancy && currentVacancy.description 
            }))
            if(response.status === 202){
                toast.success('Edited successfuly', {
                    position: "bottom-center",                                
                    closeOnClick: true,                                
                    progress: undefined,
                    theme: "colored",
                    });
            }else{
                toast.error('Something went wrong', {
                    position: "bottom-center",                                
                    closeOnClick: true,                                
                    progress: undefined,
                    theme: "colored",
                    });    
            }
        }catch(err){
            console.log(err)
            toast.error('Something went wrong', {
                position: "bottom-center",                                
                closeOnClick: true,                                
                progress: undefined,
                theme: "colored",
                });    
        }
    }

  return (
    <div className='navDebug vacancyEdit'>
        <div className='changeNotes'>
            <div>
                <h4>Title</h4>
                <input defaultValue={currentVacancy && currentVacancy.title} type="text" onChange={(e)=>{setTitle(e.target.value)}}/>
            </div>
            <div>
                <h4>Notes</h4>
                <input defaultValue={currentVacancy && currentVacancy.notes} type="text" onChange={(e)=>{setNotes(e.target.value)}}/>
            </div>
            <div>
                <h4>Description</h4>
                <textarea defaultValue={currentVacancy && currentVacancy.description} onChange={(e)=>{setDescription(e.target.value)}}/>
            </div>
            <button onClick={handleEdit}>Edit</button>
        </div>
    </div>
  )
}

export default VacancyEdit