import React, { useState } from 'react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router'
import { useRecoilState } from 'recoil'
import { deleteSector } from '../../utils/api'
import { baseUrlState } from '../../utils/atoms'
import useSectors from './../../utils/hooks/useSectors'
import './css.css'

const Sectors = () => {

    const [update, setUpdate] = useState(false)
    const [sectors] = useSectors(update)
    const navigate = useNavigate()
    const [url] = useRecoilState(baseUrlState)

    const handleDelete = async(id) => {
        await deleteSector(id)
        setUpdate(!update)
    }

    const renderSectors = useMemo(()=>{
        return sectors && sectors.map((elem, i)=>{                    
            return(
                <div className='Panel' key={i} style={{backgroundColor: elem.body_color, color: elem.accent_color}}>                    
                    <img src={url + elem.image} alt="sectorImg"/>                
                    <h3>{elem.title}</h3>
                    <p></p>
                    <ul>
                        <li>Solutions in: {elem.solution_translations.length}</li>
                    </ul>
                    <div className='smallPanelControls'>
                        <button onClick={()=>{navigate(`/admin/sectors/${elem.id}/edit`)}}>Edit</button>
                        <button onClick={()=>{handleDelete(elem.id)}}>Delete</button>
                    </div>
                </div>
            )
        })
    }, [sectors, update]) 

  return (
    <div className='sectors headerLayout'>
        <h1>Sector Configurator</h1>
        <div className='panelHolder'>
            {renderSectors}
            <div className='Panel emptyPanel' onClick={()=>{navigate('/admin/sectors/add')}}>
                <span>+</span>
            </div>
        </div>
    </div>
  )
}

export default Sectors