import { useEffect } from 'react'
import { useState } from 'react'
import {getCurrentPartner} from './../api'
import {useAuth} from './../auth'

const useCurrentPartner = (id) => {
  
    const [currentPartner, setCurrentPartner] = useState()
    const auth = useAuth()
    
    const getData = async (setFunc) => {
        try{
            const response = await getCurrentPartner(id)

            setFunc(response.data)
        }catch(err){
            console.log(err)
            if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
                alert('Connection Timed Out')
                auth.logout()
            } 
        }
    }

    useEffect(()=>{
        getData(setCurrentPartner)
    }, [auth.user])

    return [currentPartner]

}

export default useCurrentPartner;