import { useEffect } from 'react'
import { useState } from 'react'
import { getCurrentCard } from '../api'
import { useAuth } from '../auth'

const useCurrentCard = (id) => {
    
    const [card, setCard] = useState()
    const auth = useAuth()

    const getData = async(setFunc) => {
        try{
            const response = await getCurrentCard(id)

            setFunc(response.data)
        }catch(err){
            console.log(err)
            if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
                alert('Connection Timed Out')
                auth.logout()
            } 
        }
    }

    useEffect(()=>{
        getData(setCard)
    }, [])

    return [card]
 

}

export default useCurrentCard