import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './css.css'
import {addService} from './../../../utils/api'
import { toast } from 'react-toastify'
import {ArrowBack} from './../../../assets/icons'

const ServicesAdd = () => {

  const navigate = useNavigate()

  const {solutionId} = useParams()
  const [title, setTitle] = useState()
  const [description, setDescription] = useState()

  const handleCreate = async() =>{
    try{
      const fd = new FormData()
      fd.append('solution_id', solutionId)
      fd.append('title', title)
      description && fd.append('description', description)

      const response = await addService(fd)

      if(response.status === 201) {
        toast.success('Created successfuly', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });
      }else{
        toast.error('Something went wrong', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          }); 
      }

    }catch(err){
      console.log(err)
      toast.error('Something went wrong', {
        position: "bottom-center",                                
        closeOnClick: true,                                
        progress: undefined,
        theme: "colored",
        }); 
    }
  }

  return (
    <div className='navDebug servicesAdd'>
      <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
      <div className='changeNotes'>
        <div>
          <h4>Title*</h4>
          <input placeholder='Min length: 5' onChange={(e)=>{setTitle(e.target.value)}} type="text"/>
        </div>
        <div>
          <h4>Description</h4>
          <input placeholder='Min length: 10' onChange={(e)=>{setDescription(e.target.value)}} type="text"/>
        </div>
        <p>You'll be able to add other parameters after creation, in the Edit Menu</p>
        <button onClick={()=>{handleCreate()}}>Create</button>
      </div>
    </div>
  )
}

export default ServicesAdd