import React from 'react'
import Brochures from '../../components/Brochures'
import './css.css'

const Files = () => {
  return (
    <div className='navDebug'>
        <Brochures />
    </div>
  )
}

export default Files