import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import useAdmin from './../hooks/useAdmin'

const AuthContext = createContext(null)

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(null)
    const [admin] = useAdmin()
    const location = useLocation()
    const navigate = useNavigate()

    const login = (user) => {
        setUser(user)
    }

    const logout = () => {
        setUser(null)
        sessionStorage.removeItem('hansonSecretJwtTokenCipher')
    }

    useEffect(()=>{    
        if(admin && admin.status === 200){
            setUser(admin.data.username)
        }   
        navigate('/')
    }, [admin])


    // checkToken
    useEffect(()=>{
        if(admin && admin.status === 403){
            navigate('/login')
        }
    }, [location.pathname])


    return(
        <AuthContext.Provider value={{user, login, logout}}>{children}</AuthContext.Provider>
    )

}

export const useAuth = () => {
    return useContext(AuthContext)
}