import { useEffect, useState } from 'react'
import { getUserData } from '../api'
import { useAuth } from '../auth'

const useAdmin = () => {

    const [admin, setAdmin] = useState()    
    const auth = useAuth()

    const getData = async(setFunc) => {
        try{
            const response = await getUserData()

            setFunc(response)        
        }catch(err){
            console.log(err)
            auth.logout()            
        }
    }

    useEffect(()=>{
        getData(setAdmin)
    }, [])

  return [admin]
}

export default useAdmin