import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {addPartner} from './../../../utils/api'
import {ArrowBack} from './../../../assets/icons'
import './css.css'

const PartnersAdd = () => {
  const navigate = useNavigate()
  const [image, setImage] = useState()
  const [title, setTitle] = useState()

  const handleCreate = async() => {
    const form = new FormData()

    form.append('title', title)
    form.append('logo', image)

    try{
      const response = await addPartner(form)

      if(response.status === 201){
        toast.success('Created successfuly', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });
      }else{
        toast.error('Something went wrong', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });
      }

    }catch(err){
      console.log(err)
      toast.error('Something went wrong', {
        position: "bottom-center",                                
        closeOnClick: true,                                
        progress: undefined,
        theme: "colored",
        });
    }
  }

  return (
    <div className='navDebug partnersAdd'>
        <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
        <div className='changeNotes'>
          <div>
            <h4>Image</h4>
            <input type="file" onChange={(e)=>{setImage(e.target.files[0])}}/>
          </div>
          <div>
            <h4>Website url</h4>
            <input type="text" onChange={(e)=>{setTitle(e.target.value)}}/>
          </div>
          <button onClick={handleCreate}>Create</button>
        </div>
    </div>
  )
}

export default PartnersAdd