import { useEffect } from 'react'
import { useState } from 'react'
import { getLanguages } from '../api'
import { useAuth } from '../auth'

const useLanguages = () => {
    
    const [lang, setLang] = useState()
    const auth = useAuth()

    const getData = async (setFunc) => {
        const response = await getLanguages()

        setFunc(response.data)
    }

    useEffect(()=>{
        getData(setLang)
    }, [auth.user])

    return [lang]

}

export default useLanguages