import { useEffect, useState } from 'react'
import {useAuth} from './../auth'
import {getCurrentVacancy} from './../api'

const useCurrentVacancy = (id) => {

  const [currentVacancy, setCurrentVacancy] = useState()
  const auth = useAuth()

  const getData = async(setFunc) => {
    try{
        const response = await getCurrentVacancy(id)

        setFunc(response.data)
    }catch(err){
        console.log(err)
        if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
            alert('Connection Timed Out')
            auth.logout()
        } 
    }
  }

  useEffect(()=>{
    getData(setCurrentVacancy)
  }, [auth.user])

  return [currentVacancy]


}

export default useCurrentVacancy