import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './css.css'
import useCandidates from './../../utils/hooks/useCandidates'
import {DownloadIcon} from './../../assets/icons'
import { getCandidateFile } from '../../utils/api'

const Candidates = () => {

    const {pageNum} = useParams()
    const [update, setUpdate] = useState(false)
    const [candidates] = useCandidates(pageNum, update)
    const navigate = useNavigate()

    const handleDownload = async(candId, fileId) => {
        const resp = await getCandidateFile(candId, fileId)
        

        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', "Candidate.pdf");
        document.body.appendChild(link);
        link.click();
    }


    const renderCandidates = useMemo(()=>{
        return candidates && candidates.items.map((elem, i)=>{
            return(
                <tr key={i}>
                    <td>{elem.full_name}</td>
                    <td>{elem.phone}</td>
                    <td>{elem.vacancy.title}</td>
                    <td><button onClick={()=>{handleDownload(elem.id, elem.candidate_files[0].id)}}><DownloadIcon /></button></td>
                </tr>
            )
        })

    }, [candidates, update])

  return (
    <div className='navDebug candidates'>
        <h1>Candidates</h1>
        <div className='changeNotes'>
            <table>
                <tr>
                    <th><h3>Name</h3></th>
                    <th><h3>Phone</h3></th>
                    <th><h3>Vacancy</h3></th>
                    <th><h3>File</h3></th>                    
                </tr>
               {renderCandidates}                
            </table>
        </div>
        <div className='changePage'>
            <button onClick={()=>{
                    navigate(`/admin/candidates/${candidates && candidates.page > 1 ? candidates.page - 1 : candidates.page}`)
                    setUpdate(!update)
                }}>{`<`}</button>
            <button onClick={()=>{
                    navigate(`/admin/candidates/${candidates && candidates.total < candidates.size ? candidates.page : candidates.page + 1}`)
                    setUpdate(!update)
                }}>{`>`}</button>
        </div>
    </div>
  )
}

export default Candidates