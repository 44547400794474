import { useEffect, useState } from "react"
import { getSectors } from "../api"
import { useAuth } from "../auth"

const useSectors = (update) => {
    
    const [sectors, setSectors] = useState()
    const auth = useAuth()

    const getData = async(setFunc) => {
        try{
        const response = await getSectors()

        setFunc(response.data)
        }catch(err){
            console.log(err)
            if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
                alert('Connection Timed Out')
                auth.logout()
            } 
        }
    }

    useEffect(()=>{
        getData(setSectors) 

    }, [auth.user, update])
 
    return [sectors]  

}

export default useSectors