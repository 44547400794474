import { useEffect } from 'react'
import { useState } from 'react'
import { getCandidates } from '../api'
import { useAuth } from '../auth'

const useCandidates = (page, update) => {
    
    const [candidates, setCandidates] = useState()
    const auth = useAuth()

    const getData = async(setFunc) => {
        try{
            const response = await getCandidates(page)

            setFunc(response.data)
        }catch(err){
            console.log(err)
            if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
                alert('Connection Timed Out')
                auth.logout()
            } 
        }
    }

    useEffect(()=>{
        getData(setCandidates)
    }, [auth.user, update])

    return [candidates]
 

}

export default useCandidates