import { useEffect } from 'react'
import { useState } from 'react'
import {getCurrentSolution, getServices} from './../api'
import {useAuth} from './../auth'

const useCurrentServices = (solutionId, update) => {

  const [serivces, setServices] = useState()
  const auth = useAuth()

  const getData = async(setFunc) => {
        try{
            const response = await getServices()
            const currSolution = await getCurrentSolution(solutionId)

            const solution = currSolution.data.title
            const currServices = []
        

            for (const i of response.data) {
                if(solution === i.solution.title){
                    currServices.push(i)
                }
            }
            
            setFunc(currServices)

        }catch(err){
            console.log(err)
            if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
                alert('Connection Timed Out')
                auth.logout()
            } 
        }    
    }

    useEffect(()=>{
        getData(setServices)
    }, [auth.user, update])

    return [serivces]

}

export default useCurrentServices