import React from 'react'
import './css.css'
import {Exit, NavArrow} from './../../assets/icons'
import {Logo} from './../../assets/svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../utils/auth'
import { useEffect } from "react";

const Navbar = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  const location = useLocation()

  useEffect(()=>{
    window.scrollTo(0, 0)
  }, [location.pathname])

  const handleLogout = () => {
    auth.logout()
    navigate('/login')
  }

  return (
    <div className={`navbar ${!auth.user && 'displayNone'}`}>
        <div className='navLogo'>
          <Logo />
        </div>
        <div className='navList'>
          <div className='navUser'>
            <div>
              <h3>{auth.user}</h3>
              <span>Job Title</span>
            </div>
            <div onClick={handleLogout}>
              <Exit />
            </div>
          </div>
          <div className='navRoutes'>            
            <span>Pages</span>
            <ul>              
              <li onClick={()=>{navigate('/')}}>Home Page <NavArrow /></li>
              <li onClick={()=>{navigate('/admin/About')}}>Who we are <NavArrow /></li>
              <li onClick={()=>{navigate('/admin/Business')}}>Business with us <NavArrow /></li>
              <li onClick={()=>{navigate('/admin/Connect')}}>Connect us <NavArrow /></li>
              <li onClick={()=>{navigate('/admin/Files')}}>Our files <NavArrow /></li>
            </ul>
            <span>Other</span>
            <ul>
              <li onClick={()=>{navigate('/admin/candidates/1')}}>Candidates <NavArrow /></li>
              {/* <li onClick={()=>{navigate('/admin/auth/accounts')}}>Authentification <NavArrow /></li> */}
            </ul>
          </div>
        </div>
    </div>
  )
}

export default Navbar