import Partners from '../../components/Partners'
import './css.css'

const Business = () => {
  return (
    <div className='navDebug'>
        <Partners />
    </div>
  )
}

export default Business