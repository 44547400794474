import { useEffect } from "react"
import { useState } from "react"
import {getBrochures} from '../api'
import { useAuth } from "../auth"


const useBrochures = (update) => {
  const [brochures, setBrochures] = useState()
  const auth = useAuth()

  const getData = async(setFunc) => {
    try{
        const response = await getBrochures()

        setFunc(response.data)
    }catch(err){
        console.log(err)
        if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
          alert('Connection Timed Out')
          auth.logout()
      } 
    }
  }

  useEffect(()=>{
    getData(setBrochures)
  }, [update])

  return [brochures]

}

export default useBrochures