import React from 'react'
import './css.css'
import poster from './../../assets/img/main.jpg'
import { useState } from 'react'
import {Log_in} from './../../utils/api'
import { useAuth } from '../../utils/auth'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'

const Login = () => {
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()
    const auth = useAuth()
    const navigate = useNavigate()

    const refreshPage = () => {
        navigate(0)
    }

    const handleLogin = async (e) => {
        try{
            const response = await Log_in(new URLSearchParams({
                "username": username,
                "password": password
            })) 
            if(response.status === 200){
                auth.login(username)
                sessionStorage.setItem("hansonSecretJwtTokenCipher", response.data.access_token)                                
                navigate('/')
                refreshPage()
            }else{                
                toast.error('Something went wrong', {
                    position: "bottom-center",                    
                    closeOnClick: true,                                        
                    progress: undefined,
                    theme: "colored",
                });
            }
        }catch(err){
            console.log(err)            
            toast.error('Please enter correct details', {
                position: "bottom-center",                    
                closeOnClick: true,                                        
                progress: undefined,
                theme: "colored",
            });
        }
    }



  return (
    <div className='loginContainer'>
        <div className='loginForm'>   
            <div>
                <h2>Login</h2>
                <span>Welcome back Please enter your details .</span>
            </div> 
            <div>
                <form onSubmit={(e)=>{
                        e.preventDefault()
                        handleLogin()
                    }}>
                    <div>
                        <div>                        
                            <input required placeholder='Login' type="text" onChange={(e)=> {setUsername(e.target.value)}} />
                        </div>
                        <div>                
                            <input required placeholder='Password' type="password" onChange={(e)=>{setPassword(e.target.value)}}/>
                        </div>
                    </div>
                    <div>
                        <button>Login</button>
                    </div>
                </form>
            </div>   
        </div>
    </div>
  )
}

export default Login