export const Exit = () => {
    return(
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 6.99999C0.5 8.36506 0.533172 9.34253 0.64133 10.0719C0.748755 10.7963 0.923619 11.2255 1.17062 11.5294C1.41903 11.835 1.78484 12.0693 2.39018 12.3166C2.84188 12.5011 3.38444 12.6773 4.06273 12.8975C4.30767 12.977 4.5703 13.0622 4.85275 13.1557C5.86152 13.4896 6.57858 13.5599 7.08986 13.4551C7.56939 13.3569 7.91114 13.0963 8.17397 12.6337C8.4505 12.1471 8.63642 11.4386 8.74789 10.4762C8.85855 9.52066 8.8913 8.36115 8.8913 6.99999C8.8913 5.63883 8.85855 4.47933 8.74789 3.52378C8.63642 2.56136 8.4505 1.85293 8.17398 1.36628C7.91114 0.90372 7.56939 0.643105 7.08986 0.544858C6.57858 0.440105 5.86152 0.510444 4.85274 0.844285C4.57034 0.937743 4.30774 1.02299 4.06283 1.10249C3.38449 1.32269 2.8419 1.49883 2.39018 1.68337C1.78484 1.93067 1.41903 2.16496 1.17062 2.4706C0.923619 2.7745 0.748756 3.20368 0.64133 3.92809C0.533172 4.65745 0.5 5.63492 0.5 6.99999Z" stroke="#2E68FF" strokeLinecap="round" strokeLinejoin="round"/>
            <ellipse rx="0.521739" ry="0.56" transform="matrix(-1 0 0 1 6.78258 7.09458)" fill="#2E68FF"/>
            <path d="M8.86957 12.04C11.4783 12.04 11.4783 11.3591 11.4783 7.09063C11.4783 2.82216 11.4783 1.95999 8.86957 1.95999" stroke="#2E68FF" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
export const NavArrow = () => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 10C1 12.0359 1.11039 13.6037 1.38064 14.8197C1.64835 16.0243 2.05947 16.8215 2.61898 17.381C3.1785 17.9405 3.97568 18.3516 5.18031 18.6194C6.39629 18.8896 7.96407 19 10 19C12.0359 19 13.6037 18.8896 14.8197 18.6194C16.0243 18.3516 16.8215 17.9405 17.381 17.381C17.9405 16.8215 18.3516 16.0243 18.6194 14.8197C18.8896 13.6037 19 12.0359 19 10C19 7.96407 18.8896 6.39629 18.6194 5.18031C18.3516 3.97569 17.9405 3.1785 17.381 2.61898C16.8215 2.05947 16.0243 1.64835 14.8197 1.38064C13.6037 1.1104 12.0359 1 10 1C7.96407 1 6.39629 1.1104 5.18031 1.38064C3.97568 1.64835 3.1785 2.05947 2.61898 2.61898C2.05947 3.1785 1.64835 3.97569 1.38064 5.18031C1.11039 6.39629 1 7.96407 1 10Z" stroke="#2E68FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.33335 6.66669L11.6667 10L8.33335 13.3334" stroke="#2E68FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
export const EditIcon = () => {
    return( 
        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M7,17V9.93L13.93,3l0,0H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-8.93L14.07,17H7z"/><polygon points="9,15 13.24,15 20.44,7.8 16.2,3.56 9,10.76"/><path d="M22.91,2.49l-1.41-1.41c-0.78-0.78-2.05-0.78-2.83,0l-1.06,1.06l4.24,4.24l1.06-1.06C23.7,4.54,23.7,3.27,22.91,2.49z"/></g></g></svg>
    )
}
export const ArrowBack = () => {
    return(
        <svg width="18" height="12" viewBox="0 0 18 12" xmlns="http://www.w3.org/2000/svg" >
            <path d="M6 1L1 6M1 6H17M1 6L6 11" stroke="#08A0F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"  />
        </svg>

    )
}

export const DownloadIcon = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M5.5 16q-.625 0-1.062-.438Q4 15.125 4 14.5V13h1.5v1.5h9V13H16v1.5q0 .625-.438 1.062Q15.125 16 14.5 16Zm4.5-3L6 9l1.062-1.062 2.188 2.187V3h1.5v7.125l2.188-2.187L14 9Z"/></svg>
    )
}
