import React, { useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { editCarousel } from '../../../utils/api'
import useLanguages from './../../../utils/hooks/useLanguages'
import {ArrowBack} from './../../../assets/icons'
import useCurrentCarousel from './../../../utils/hooks/useCurrentCarousel'
import './css.css'

const CarouselEdit = () => {

    const [lang] = useLanguages()
    const {itemId} = useParams()
    const [currentCarousel] = useCurrentCarousel(itemId)
    const [image, setImage] = useState(null)
    const [description, setDescription] = useState(null)
    const navigate = useNavigate()
    // const [lang_, setLang] = useState(undefined)

    // const renderLanguages = useMemo(()=>{
    //     return lang && lang.map((elem, i)=>{
    //         return(
    //             <option key={i}>{elem.code}</option>
    //         )
    //     })
    // }, [lang])

    const handleSend = async() => {
        const fd = new FormData()

        description && fd.append('title', description)
        image && fd.append("image", image)
        // lang_ && fd.append('lang', lang_)

        try{
        const response = await editCarousel(itemId, fd)

        if(response.status === 202){
            toast.success('Edited successfuly', {
                position: "bottom-center",                                
                closeOnClick: true,                                
                progress: undefined,
                theme: "colored",
                });
        }
        }catch(err){
            console.log(err)
            toast.error('Something went wrong', {
                position: "bottom-center",                                
                closeOnClick: true,                                
                progress: undefined,
                theme: "colored",
                });
        }

    }


    console.log(currentCarousel)

  return (
    <div className='navDebug carouselEdit'>
        <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
        <div className='changeNotes'>
            <div>
                <h4>Image</h4>
                <input onChange={(e)=>{setImage(e.target.files[0])}} type="file"/>
            </div>
            <div>
                <h4>Description</h4>
                <textarea defaultValue={currentCarousel && currentCarousel.title } onChange={(e)=>{setDescription(e.target.value)}} />
            </div>
            {/* <select onChange={(e)=>{setLang(e.target.value)}}>
                <option disabled selected>Select Language</option>
                {renderLanguages}
            </select> */}
            <button onClick={handleSend}>Edit</button>
        </div>
    </div>
  )
}

export default CarouselEdit