import { useEffect } from 'react'
import { useState } from 'react'
import {getNews} from './../api'
import {useAuth} from './../auth/'

const useNews = (update) => {
    
    const [news, setNews] = useState()
    const auth = useAuth()

    const getData = async(setFunc) => {
        try{
        const response = await getNews()

        setFunc(response.data)

        }catch(err){
            console.log(err)
            if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
                alert('Connection Timed Out')
                auth.logout()
            } 
        }
    }

    useEffect(()=>{
        getData(setNews)
    }, [auth.user, update])

    return [news]

}

export default useNews