import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import useCurrentNews from './../../../utils/hooks/useCurrentNews'
import {baseUrlState} from './../../../utils/atoms'
import { useEffect } from 'react'
import { EditNews } from '../../../utils/api'
import { toast } from 'react-toastify'
import {ArrowBack} from './../../../assets/icons'
import './css.css'

const NewsEdit = () => {

    const {itemId} = useParams()
    const [currentNews] = useCurrentNews(itemId)
    const navigate = useNavigate()

    const [header, setHeader] = useState(undefined)
    const [sphere, setSphere] = useState(undefined)
    const [description, setDescription] = useState(undefined)
    const [image, setImage] = useState(undefined)
    const [preview, setPreview] = useState(undefined)

    const [url] = useRecoilState(baseUrlState)


    const onSelectedFile = (e) => {
      if(!e.target.files || e.target.files.length === 0) {
        setImage(undefined)
        return
      }
      setImage(e.target.files[0])
    }

    useEffect(()=>{
      if(!image){
        setPreview(undefined)
        return
      }
      const objectUrl = URL.createObjectURL(image)
      setPreview(objectUrl)

      return () => URL.revokeObjectURL(objectUrl) 
    }, [image])


    const handleEdit = async () => {
      
      const fd = new FormData()

      header && fd.append('title', header)
      description && fd.append('description', description)
      sphere && fd.append('sphere', sphere)
      image && fd.append('image', image)

      try{
        const response = await EditNews(itemId, fd)

        if(response.status === 202){
          toast.success('Edited successfuly', {
            position: "bottom-center",                                
            closeOnClick: true,                                
            progress: undefined,
            theme: "colored",
            });
        }else{
          toast.error('Something went wrong', {
            position: "bottom-center",                                
            closeOnClick: true,                                
            progress: undefined,
            theme: "colored",
            });
        }
      }catch(err){
        console.log(err)
        toast.error('Something went wrong', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });
      }


    }



  return (
    <div className='navDebug newsEdit'>
        <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
        <div className='newsPanelHolder'>
          <div className='newsPanel'>
                <div className='newsCompanyLogo'>
                    <div className='newsLogo'>
                        <img src={image === undefined ? currentNews && url + currentNews.news.image : preview} alt='companyLogo'/>
                    </div>
                    <div>
                        <h2>{header === undefined ? currentNews && currentNews.title : header}</h2>
                        <span>{ sphere === undefined ? currentNews && currentNews.sphere : sphere}</span>
                    </div>
                </div>
                <div>
                    <p>
                        {description === undefined ? currentNews && currentNews.description : description}
                    </p>
                </div>
            </div>
        </div>

        
        <div className='changeNotes'>
            <div>
              <h4>Image</h4>
              <input type="file" onChange={(e)=>{onSelectedFile(e)}}/>
            </div>
            <div>
              <h4>Title</h4>
              <input defaultValue={currentNews && currentNews.title} onChange={(e)=>{setHeader(e.target.value)}} type="text"/>
            </div>
            <div>
              <h4>Sphere</h4>
              <input defaultValue={currentNews && currentNews.sphere} onChange={(e)=>{setSphere(e.target.value)}} type="text" />
            </div>
            <div>
              <h4>Description</h4>
              <textarea defaultValue={currentNews && currentNews.description} onChange={(e)=>{setDescription(e.target.value)}}/>
            </div>
            <button onClick={handleEdit}>Edit</button>
        </div>
    </div>
  )
}

export default NewsEdit