import React from 'react'
import Carousel from '../../components/Carousel'
import News from '../../components/News'
import Sectors from '../../components/Sectors'
import './css.css'

const HomePage = () => {
  return (
    <div className='navDebug'>
      <Carousel />
      <Sectors />
      <News />
    </div>
  )
}

export default HomePage