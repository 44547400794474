import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addSector } from '../../../utils/api'
import {ArrowBack} from './../../../assets/icons'
import './css.css'
import './../edit/css.css'


const SectorAdd = () => {
  const navigate = useNavigate()
  const [bodyColor, setBodyColor] = useState()
  const [accentColor, setAccentColor] = useState()
  const [image, setImage] = useState()
  const [preview, setPreview] = useState()

  const [title, setTitle] = useState()
  const [description, setDescription] = useState()

  const getBodyColor = (color) => {
    setBodyColor(color.hex)
  }
  const getAccentColor = (color) => {
    setAccentColor(color.hex)
  }

  const onSelectedFile = (e) => {
    if(!e.target.files || e.target.files.length === 0) {
      setImage(undefined)
      return
    }
    setImage(e.target.files[0])
  }

  useEffect(()=>{
    if(!image){
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(image)
    setPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl) 
  }, [image])
  

  const handleCreate = async() => {
    const fd = new FormData()

    fd.append('title', title) 
    fd.append('description', description)
    fd.append('image', image)
    fd.append('body_color', bodyColor)
    fd.append('accent_color', accentColor)

    try{
      const response = await addSector(fd)

      if(response.status === 201){
        toast.success('Created successfuly', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });
      }else{
        toast.error('Something went wrong', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });   
      }
    }catch(err){
      console.log(err)
      toast.error('Something went wrong', {
        position: "bottom-center",                                
        closeOnClick: true,                                
        progress: undefined,
        theme: "colored",
        });   
    }

  }


  return (
    <div className='navDebug sectorAdd'>
        <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>

        <p>The preview below is just a rough example of how it will look on the site.</p>
        <div className='sectorPreview' style={{backgroundColor: bodyColor === undefined ? "white" : bodyColor, color: accentColor === undefined ? "black" : accentColor}}>
          <div>
            <h1>{title === undefined ? "Title" : title}</h1>
            <ul>
              <li>Sample Text</li>
              <li>Sample Text</li>
              <li>Sample Text</li>
              
            </ul>
            <p>{description === undefined ? "Description" : description}</p>
          </div>
          <div>
            <img src={preview === undefined ? "" : preview} alt="Img Preview"/>
          </div>
        </div>

        <div className="changeNotes">
          <div>
            <h4>Image</h4>
            <input onChange={(e)=>{onSelectedFile(e)}} type="file"/>
          </div>
          <div className='flexBox'>
            <div>
              <h4>Body color</h4>
              <SketchPicker color={bodyColor} onChange={getBodyColor} />
            </div>
            <div>
              <h4>Text/accent color</h4>
              <SketchPicker color={accentColor} onChange={getAccentColor} />
            </div>
          </div>
          <div>
            <h4>Title</h4>
            <input onChange={(e)=>{setTitle(e.target.value)}} placeholder='Min length: 5' type="text"/>
          </div>
          <div>
            <h4>Description</h4>
            <textarea onChange={(e)=>{setDescription(e.target.value)}} placeholder='Min length: 10' />            
          </div>
          <button onClick={()=>{handleCreate()}}>Create</button>
        </div>
    </div>
  )
}

export default SectorAdd