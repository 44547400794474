import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addBrochure } from '../../../utils/api'
import './css.css'
import {ArrowBack} from './../../../assets/icons'

const BrouchuresAdd = () => {

  const navigate = useNavigate()
  const [title, setTitle] = useState()
  const [file, setFile] = useState()

  const handleCreate = async() => {
    try{
      const fd = new FormData()

      fd.append("title", title)
      fd.append("file", file)
      const response = await addBrochure(fd)

      if(response.status === 200){
        toast.success('Created successfuly', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });
      }else{
        toast.error('Something went wrong', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          }); 
      }
    }catch(err){
      console.log(err)
      toast.error('Something went wrong', {
        position: "bottom-center",                                
        closeOnClick: true,                                
        progress: undefined,
        theme: "colored",
        }); 
    }

  }

  return (
    <div className='navDebug brochureAdd'>
      <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
      <h1>Add Brochure</h1>
      <div className='changeNotes'>
        <div>
          <h4>File</h4>
          <p>only pdf allowed</p>
          <input onChange={(e)=>{setFile(e.target.files[0])}} type="file"/>
        </div>
        <div>
          <h4>Title</h4>
          <input placeholder='Min length: 5' onChange={(e)=>{setTitle(e.target.value)}} type="text"/>
        </div>
        <button onClick={handleCreate}>Add</button>
      </div>
    </div>
  )
}

export default BrouchuresAdd