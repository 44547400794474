import { useEffect } from 'react'
import { useState } from 'react'
import {getCurrentNews} from './../api'
import {useAuth} from './../auth'

const useCurrentNews = (id) => {
  
    const [currentNews, setCurrentNews] = useState()
    const auth = useAuth()
    
    const getData = async (setFunc) => {
        try{
            const response = await getCurrentNews(id)

            setFunc(response.data)
        }catch(err){
            console.log(err)
            if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
                alert('Connection Timed Out')
                auth.logout()
            } 
        }
    }

    useEffect(()=>{
        getData(setCurrentNews)
    }, [auth.user])

    return [currentNews]

}

export default useCurrentNews