import React from 'react'
import Cards from '../../components/Cards'
import './css.css'

const About = () => {
  return (
    <div className='navDebug'>
        <Cards />
    </div>
  )
}

export default About