import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './css.css'

import useBrochures from './../../utils/hooks/useBrochures'
import { deleteBrochure } from '../../utils/api'

const Brochures = () => {

    const navigate = useNavigate()
    const [update, setUpdate] = useState(false)
    const [brochures] = useBrochures(update)
    
    const handleDelete = async (id) => {
        await deleteBrochure(id)
        setUpdate(!update)
    }

    const renderBrochures = useMemo(()=>{
        return brochures && brochures.map((elem, i)=>{
            return(
                <div className='rowPanel' key={i}>
                    <div>
                        <p>{elem.title}</p>
                    </div>
                    <div>
                        <p>{elem.size}</p>
                    </div>
                    <div className='editButtons'>
                    <button className='displayNone'></button>
                    <button onClick={()=>{handleDelete(elem.id)}}>X</button>
                    </div>
                </div>
            )
        })
    }, [brochures, update])

  return (
    <div className='headerLayout brochure'>
        <h1>Brochures configurator</h1>
        <div className='rowPanelHolder'>
            <div className='rowPanel'>
                <div>
                    <p>Title</p>
                </div>
                <div>
                    <p>size</p>
                </div>
                <div>
                    <p>Actions</p>
                </div>
            </div>
            {renderBrochures}
            <div className='rowPanel emptyPanel emptyRowPanel' onClick={()=>{navigate('/admin/brochures/add')}}>
                <span>+</span>
            </div>
        </div>
    </div>
  )
}

export default Brochures