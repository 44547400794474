import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {addNews} from './../../../utils/api'
import {ArrowBack} from './../../../assets/icons'
import './css.css'

const NewsAdd = () => {

  const navigate = useNavigate()

  const [image, setImage] = useState(undefined)
  const [title, setTitle] = useState(undefined)
  const [sphere, setSphere] = useState(undefined)
  const [description, setDescription] = useState(undefined)

  const handleCreate = async() => {
    
    const form = new FormData()

    form.append('title', title)
    form.append('description', description)
    form.append('sphere', sphere)
    form.append('image', image)

    try{
      const response = await addNews(form)

      if(response.status === 201){
        toast.success('Created successfuly', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });
      }else{
        toast.error('Something went wrong', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });  
      }

    }catch(err){
      console.log(err)
      toast.error('Something went wrong', {
        position: "bottom-center",                                
        closeOnClick: true,                                
        progress: undefined,
        theme: "colored",
        });
    }
  }

  return (
    <div className='navDebug newsAdd'> 
        <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
        <h1>Create News</h1>
        <div className='changeNotes'>
            <div>
              <h4>Image</h4>
              <input type="file" onChange={(e)=>{setImage(e.target.files[0])}}/>
            </div>
            <div>
              <h4>Title</h4>
              <input type="text" onChange={(e)=>{setTitle(e.target.value)}}/>
            </div>
            <div>
              <h4>Sphere</h4>
              <input type="text" onChange={(e)=>{setSphere(e.target.value)}}/>
            </div>
            <div>
              <h4>Description</h4>
              <textarea onChange={(e)=>{setDescription(e.target.value)}} />
            </div>         
            <button onClick={handleCreate}>Create</button>   
        </div>
    </div>
  )
}

export default NewsAdd