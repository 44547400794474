import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EditIcon } from '../../assets/icons'
import { deleteVacancy } from '../../utils/api'
import useVacancies from './../../utils/hooks/useVacancies'
import './css.css'

const Vacancy = () => {

  const navigate = useNavigate()
  const [update, setUpdate] = useState()
  const [vacancy] = useVacancies(1, update)

  const handleDelete = async(id) => {
    await deleteVacancy(id)
    setUpdate(!update)
  }

  const renderVacancy = useMemo(()=>{
      return vacancy && vacancy.items.map((elem, i)=>{
        return(
          <div className='rowPanel' key={i}>
              <div>
                <h3>{elem.title}</h3>
              </div>
              <div>
                <p>{elem.description}</p>
              </div>
              <div className='editButtons'>
                  <button onClick={()=>{navigate(`/admin/vacancy/${elem.id}/edit`)}}><EditIcon /></button>
                  <button onClick={()=>{handleDelete(elem.id)}}>X</button>
              </div>
          </div>
        )
      })
  }, [vacancy])

  return ( 
    <div className='headerLayout vacancy'>
        <h1>Vacancies configurator</h1>
        <div className='rowPanelHolder'> 
            <div className='rowPanel'>
                <div>
                  <p>Title</p>
                </div>
                <div>
                  <p>Description</p>
                </div>
                <div>
                    Actions
                </div>
            </div>
            {renderVacancy}
            <div className='rowPanel emptyPanel emptyRowPanel' onClick={()=>{navigate('/admin/vacancy/add')}}>
              <span>+</span>
            </div>
        </div>
    </div>
  )
}

export default Vacancy