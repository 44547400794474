import React, { useMemo } from 'react'
import './css.css'
import useCarousel from './../../utils/hooks/useCarousel'
import { useRecoilState } from 'recoil'
import {baseUrlState} from './../../utils/atoms'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../utils/auth'
import { deleteCarousel } from '../../utils/api'
import { useState } from 'react'

const Carousel = () => {

  const navigate = useNavigate()
  const auth = useAuth()
  const [handleUpdate, setHandleUpdate] = useState(false)
  const [carousel] = useCarousel(handleUpdate)
  const [url] = useRecoilState(baseUrlState)


  const handleDelete = async(id) => {
    await deleteCarousel(id)
    setHandleUpdate(!handleUpdate)
  }


  const renderCarousel = useMemo(()=>{
    return carousel && carousel.map((elem, i)=>{      
      return(
          <div key={i} className='Panel'>
              <img src={url + elem.image} alt="Error Loading"/>
              <p>{elem.title}</p>
              <div className='panelControls'>
                  <button onClick={()=>{navigate(`/admin/carousel/${elem.id}/edit`)}}>Edit</button>
                  {/* <button>Add Translation</button> */}
                  <button onClick={()=>{handleDelete(elem.id)}}>Delete</button>
              </div> 
          </div>
      )
    }) 
  }, [carousel, auth.user, handleUpdate])

  return (
    <div className='carousel headerLayout'>
        <h1>Poster Carousel Configurator</h1>
        <div className='panelHolder'>
            {renderCarousel}
            <div className='emptyPanel Panel' onClick={()=>{navigate('/admin/carousel/add')}}>
              <span>+</span>
            </div>
        </div>
    </div>
  ) 
}

export default Carousel