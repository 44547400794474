import React from 'react'
import { useMemo } from 'react'
import './css.css'
import useNews from './../../utils/hooks/useNews'
import { useRecoilState } from 'recoil'
import {baseUrlState} from './../../utils/atoms'
import { deleteNews } from '../../utils/api'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const News = () => {

    const [update, setUpdate] = useState(false)
    const [news] = useNews(update)
    const [url] = useRecoilState(baseUrlState)
    const navigate = useNavigate()


    const handleDelete = async(id) => {
        try{
            await deleteNews(id)
            setUpdate(!update)
        }catch(err){
            console.log(err)
        }
    }

    const renderNews = useMemo(()=>{
        return news && news.map((elem, i)=>{        
            return(
                <div className='smallPanel' key={i}>
                    <div className='panelImgHolder'>
                        <img src={url + elem.image} alt="companyLogo"/>
                    </div>
                    <h3>{elem.title}</h3>
                    <div className='smallPanelControls'>
                        <button onClick={()=>{navigate(`/admin/news/${elem.id}/edit`)}}>Edit</button>
                        <button onClick={()=>{handleDelete(elem.id)}}>Delete</button>
                    </div>
                </div>
            )
        })
    }, [news, update])

  return (
    <div className='news headerLayout'>
        <h1>News Configurator</h1>
        <div className='panelHolder'>
            {renderNews}
            <div className='emptyPanel smallPanel' onClick={()=>{navigate('/admin/news/add')}}>
                <span>+</span>
            </div>
        </div>
    </div>
  )
}

export default News
