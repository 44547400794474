import React from 'react'
import './css.css'

const Modal = (props) => {
  return (
    <div>
        {
            props.content
        }
        {/* { props.alpha} */}
    </div>
  )
}

export default Modal