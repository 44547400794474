import axios from "axios";
// const axiosInstance = axios.create({baseURL: 'http://172.18.17.210:7100/api/v1'})
const axiosInstance = axios.create({baseURL: 'https://api.hansonint.net'})
const currentLang = "en"
axiosInstance.defaults.headers["authorization"] =  `Bearer ${sessionStorage.getItem('hansonSecretJwtTokenCipher')}`



// Other
const Log_in = (form) => {
    return axiosInstance.post(`/authentication/admin/sign_in`, form)
}
const getUserData = () => {
    return axiosInstance.get(`/users/admin/me`)
}
const getLanguages = () => {
    return axiosInstance.get(`/languages/admin/all`)
}


// Carousel
const getCarousel = () => {
    return axiosInstance.get(`/carousel/admin/all`)
}
const addCarousel = (form) => {
    return axiosInstance.post(`/carousel/admin/create`, form)
}
const getCurrentCarousel = (id) => {
    return axiosInstance.get(`/carousel/admin/${id}`)
}
const editCarousel = (id, form) => {
    return axiosInstance.put(`/carousel/admin/${id}`, form)
}
const deleteCarousel = (id) => {
    return axiosInstance.delete(`/carousel/admin/${id}`)
}


// News
const getNews = () => {
    return axiosInstance.get(`/news/admin/all`)
}
const getCurrentNews = (id) => {
    return axiosInstance.get(`/news/admin/${id}?lang=${currentLang}`)
}
const addNews = (form) => {
    return axiosInstance.post(`/news/admin/create`, form)
}
const EditNews = (id, form) => {
    return axiosInstance.put(`/news/admin/${id}`, form)
}
const deleteNews = (id) => {
    return axiosInstance.delete(`/news/admin/${id}`)
}


// Partners
const getPartners = () => {
    return axiosInstance.get(`/partners/admin/all`)
}
const getCurrentPartner = (id) => {
    return axiosInstance.get(`/partners/admin/${id}`)
}
const addPartner = (form) => {
    return axiosInstance.post(`/partners/admin/create`, form)
}
const editPartner = (id, form) => {
    return axiosInstance.put(`/partners/admin/${id}`, form)
}
const deletePartners = (id) => {
    return axiosInstance.delete(`/partners/admin/${id}`)
}


// Cards
const getCards = () => {
    return axiosInstance.get(`/cards/admin/all`)
} 
const getCurrentCard = (id) => {
    return axiosInstance.get(`/cards/admin/${id}`)
}
const addCard = (form) => {
    return axiosInstance.post(`/cards/admin/create`, form)
}
const editCard = (id, form) => {
    return axiosInstance.put(`/cards/admin/${id}`, form)
}
const deleteCard = (id) => {
    return axiosInstance.delete(`/cards/admin/${id}`)
}


// Sectors
const getSectors = () => {
    return axiosInstance.get(`/sectors/admin/all`)
}
const getCurrentSector = (id) => {
    return axiosInstance.get(`/sectors/admin/${id}`)
}
const addSector = (form) => {
    return axiosInstance.post(`/sectors/admin/create`, form)
}
const editSector = (id, form) => {
    return axiosInstance.put(`/sectors/admin/${id}`, form)
}
const deleteSector = (id) => {
    return axiosInstance.delete(`/sectors/admin/${id}`)
}


// Solutions
const addSolution = (form) => {
    return axiosInstance.post(`/solutions/admin/create`, form) 
}
const getCurrentSolution = (id) => {
    return axiosInstance.get(`/solutions/admin/${id}`)
}
const editSolution = (id, form) => {
    return axiosInstance.put(`solutions/admin/${id}`, form)
}
const deleteSolution = (id) => {
    return axiosInstance.delete(`/solutions/admin/${id}`)
}


// Services
const getServices = () => {
    return axiosInstance.get(`/services/admin/all`)
} 
const getCurrentService = (id) => {
    return axiosInstance.get(`/services/admin/${id}`)
}
const addService = (form) => {
    return axiosInstance.post(`/services/admin/create`, form)
}
const editService = (id, form) => {
    return axiosInstance.put(`/services/admin/${id}`, form)
}
const deleteService = (id) => {
    return axiosInstance.delete(`/services/admin/${id}`)
}

// Service_content
const addSubItem = (servId, form) => {
    return axiosInstance.post(`/services/admin/${servId}/add_subitem_to_item`, form)
}
const addSubImage = (servId, form) => {
    return axiosInstance.post(`/services/admin/${servId}/add_item_images`, form)
}
const addSubVideo = (servId, form) => {
    return axiosInstance.post(`/services/admin/${servId}/add_item_videos`, form)
}
const deleteSubItem = (servId, subItemId) => {
    return axiosInstance.delete(`/services/admin/${servId}/delete_subitem_of_item/${subItemId}`)
}
const deleteServiceImage = (servId, imgId) => {
    return axiosInstance.delete(`/services/admin/${servId}/delete_item_image/${imgId}`)
}
const deleteSubVideo = (servId, vidId) => {
    return axiosInstance.delete(`/services/admin/${servId}/delete_item_video/${vidId}`)
}


//Vacancies 
const getVacancies = (pageNum) => {
    return axiosInstance.get(`/vacancy/admin/all?page=${pageNum}&size=50`)
}
const getCurrentVacancy = (id) => {
    return axiosInstance.get(`/vacancy/admin/${id}`)
}
const addVacancy = (form) => {
    return axiosInstance.post(`/vacancy/admin/create`, form)
} 
const editVacancy = (id, form) => {
    return axiosInstance.put(`/vacancy/admin/${id}`, form)
}
const deleteVacancy = (id) => {
    return axiosInstance.delete(`/vacancy/admin/${id}`)
}


// Brochures
const getBrochures = () => {
    return axiosInstance.get(`/brochure_file/admin/all`)
}
const addBrochure = (form) => {
    return axiosInstance.post(`/brochure_file/admin/upload_brochure`, form)
}
const deleteBrochure = (id) => {
    return axiosInstance.delete(`/brochure_file/admin/${id}`)
}


// Candidates
const getCandidates = (page) => {
    return axiosInstance.get(`/candidates/admin/all?page=${page}&size=50`)
}
const getCandidateFile = (candId, fileId) => {
    return axiosInstance.get(`/candidates/admin/${candId}/${fileId}`)
}

export {
    // Other
        Log_in,
        getUserData,
        getLanguages,

    // Carousel
        getCarousel,
        addCarousel,
        getCurrentCarousel,
        editCarousel,
        deleteCarousel,

    // News
        getNews,
        getCurrentNews,
        addNews,
        EditNews,
        deleteNews,

    // Partners
        getPartners,   
        getCurrentPartner,
        addPartner,
        editPartner,
        deletePartners,

    // Cards
        getCards,
        getCurrentCard,
        addCard,
        editCard,
        deleteCard,

    // Sectors
        getSectors,
        getCurrentSector,
        addSector,
        editSector,
        deleteSector,

    // Solutions
        addSolution,
        getCurrentSolution,
        editSolution,
        deleteSolution,

    // Services
        getServices,
        getCurrentService,
        addService,
        editService,
        deleteService,
        
    // Service_content
        addSubItem,
        addSubImage,
        addSubVideo,
        deleteSubItem,
        deleteServiceImage,
        deleteSubVideo,

    // Vacancies
        getVacancies,
        getCurrentVacancy,
        addVacancy,
        editVacancy,
        deleteVacancy,

    // Brochures
        getBrochures,
        addBrochure,
        deleteBrochure,
    
    // Candidates
        getCandidates,
        getCandidateFile,
}