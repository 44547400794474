import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'
import { editPartner } from '../../../utils/api'
import { baseUrlState } from '../../../utils/atoms'
import useCurrentPartner from './../../../utils/hooks/useCurrentPartner'
import {ArrowBack} from './../../../assets/icons'
import './css.css'

const PartnersEdit = () => {
  
  const {itemId} = useParams()
  const [currentPartner] = useCurrentPartner(itemId)
  const navigate = useNavigate()
  const [image, setImage] = useState()
  const [title, setTitle] = useState()
  const [url] = useRecoilState(baseUrlState)


  const handleEdit = async() => {
    const form = new FormData()

    title && form.append('title', title)
    image && form.append('logo', image)

    try{
      const response = await editPartner(itemId, form)

      if(response.status === 202){
        toast.success('Edited successfuly', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });
      }else{
        toast.error('Something went wrong', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });  
      }

    }catch(err){
      console.log(err)
      toast.error('Something went wrong', {
        position: "bottom-center",                                
        closeOnClick: true,                                
        progress: undefined,
        theme: "colored",
        });
    }


  }

  
  return (
    <div className='navDebug partnersEdit'>
      <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
      <div className='panelHolder panelEdit'>
        <div className='smallPanel'>
          <div className='panelImgHolder'> 
            <img src={currentPartner && url + currentPartner.logo} alt='partnerLogo'/>
          </div>
          <h3>{currentPartner && currentPartner.title}</h3>
        </div>
      </div>

      <div className='changeNotes'>
        <div>
          <h4>Image/Logo</h4>
          <input onChange={(e)=>{setImage(e.target.files[0])}} type="file"/>
        </div>
        <div>
          <h4>Website url</h4>
          <input onChange={(e)=>{setTitle(e.target.value)}} defaultValue={currentPartner && currentPartner.title} type="text"/>
        </div>
        <button onClick={handleEdit}>Edit</button>
      </div>
    </div>
  )
}

export default PartnersEdit