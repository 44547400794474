import React, { useState } from 'react'
import { toast } from 'react-toastify'
import {addVacancy} from './../../../utils/api'
import './css.css'

const VacancyAdd = () => {

  const [title, setTitle] = useState()
  const [notes, setNotes] = useState()
  const [description, setDescription] = useState()

  const handleSend = async() => {
    try{
      const response = await addVacancy(new URLSearchParams({
        "title": title,
        "notes": notes,
        "description": description
      }))

      if(response.status === 201){
        toast.success('Created successfuly', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });
      }else{
        toast.error('Something went wrong', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });    
      }
    }catch(err){
      console.log(err)
      toast.error('Something went wrong', {
        position: "bottom-center",                                
        closeOnClick: true,                                
        progress: undefined,
        theme: "colored",
        });    
    }
  }

  return (
    <div className='navDebug vacancyAdd'>
      <div className='changeNotes'>
        <div>
          <h4>Title</h4>
          <input onChange={(e)=>{setTitle(e.target.value)}} type="text"/>
        </div>
        <div>
          <h4>Notes</h4>
          <input onChange={(e)=>{setNotes(e.target.value)}} type="text"/>
        </div>
        <div>
          <h4>Description</h4>
          <textarea onChange={(e)=>{setDescription(e.target.value)}}/>
        </div>
      <button onClick={handleSend}>Create</button>
      </div>
    </div>
  )
}

export default VacancyAdd