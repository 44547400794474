import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {addCard} from  './../../../utils/api'
import {ArrowBack} from './../../../assets/icons'
import './css.css'

const CardAdd = () => {

    const [image, setImage] = useState()
    const [title, setTitle] = useState()
    const [mirror, setMirror] = useState(false)

    const navigate = useNavigate()    

    const handleCreate = async() => {

        const form = new FormData()     
        
        form.append('image', image)
        form.append('title', title)
        form.append('mirrored', mirror)

        try{
            const response = await addCard(form)

            if(response.status === 201){
                toast.success('Created successfuly', {
                    position: "bottom-center",                                
                    closeOnClick: true,                                
                    progress: undefined,
                    theme: "colored",
                    });
            }else{
                toast.error('Something went wrong', {
                    position: "bottom-center",                                
                    closeOnClick: true,                                
                    progress: undefined,
                    theme: "colored",
                    });    
            }

        }catch(err){
            console.log(err)
            toast.error('Something went wrong', {
                position: "bottom-center",                                
                closeOnClick: true,                                
                progress: undefined,
                theme: "colored",
                });
        }

    }

  return (
    <div className='navDebug cardAdd'>
        <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
        <div className='changeNotes'>
            <div>
                <h4>Image</h4>
                <p>only jpg/png allowed</p>
                <input onChange={(e)=>{setImage(e.target.files[0])}} type="file"/>
            </div>
            <div>
                <h4>Title</h4>
                <p>Minimum 10 digits</p>
                <input onChange={(e)=>{setTitle(e.target.value)}} minLength="10" type="text"/>
            </div>
            <div>
                <h4>Mirror image</h4>
                <div>
                    <span>is Mirrored - </span>
                    <input id='check' onChange={(e)=>{setMirror(e.target.checked)}} type="checkbox"/>
                </div>                
            </div>
            <button onClick={handleCreate}>Create</button>
        </div>
    </div>
  )
}

export default CardAdd