import { useEffect } from 'react'
import { useState } from 'react'
import {getCurrentSolution} from './../api'
import {useAuth} from './../auth'

const useCurrentSolution = (id) => {
  
    const [currentSolution, setCurrentSolution] = useState()
    const auth = useAuth()
    
    const getData = async (setFunc) => {
        try{
            const response = await getCurrentSolution(id)

            setFunc(response.data)
        }catch(err){
            console.log(err)
            if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
                alert('Connection Timed Out')
                auth.logout()
            } 
        }
    }

    useEffect(()=>{
        getData(setCurrentSolution)
    }, [auth.user])

    return [currentSolution]

}

export default useCurrentSolution;