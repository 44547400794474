import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'
import { editCard } from '../../../utils/api'
import { baseUrlState } from '../../../utils/atoms'
import useCurrentCard from './../../../utils/hooks/useCurrentCard'
import {ArrowBack} from './../../../assets/icons'
import './css.css'

const CardEdit = () => {

    const {itemId} = useParams()
    const [card] = useCurrentCard(itemId)

    const [url] = useRecoilState(baseUrlState)
    const navigate = useNavigate()
    const [title, setTitle] = useState()
    const [image, setImage] = useState()
    const [preview, setPreview] = useState(undefined)

    const handleEdit = async() => {
        const form = new FormData()

        image && form.append('image', image)
        title && form.append('title', title)

        try{
            const response = await editCard(itemId, form)

            if(response.status === 202){
                toast.success('Edited successfuly', {
                    position: "bottom-center",                                
                    closeOnClick: true,                                
                    progress: undefined,
                    theme: "colored",
                    });
            }else{
                toast.error('Something went wrong', {
                    position: "bottom-center",                                
                    closeOnClick: true,                                
                    progress: undefined,
                    theme: "colored",
                    });
            }

        }catch(err){
            console.log(err)
            toast.error('Something went wrong', {
                position: "bottom-center",                                
                closeOnClick: true,                                
                progress: undefined,
                theme: "colored",
                });
        }
    }


    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setImage(undefined)
            return
        }

        setImage(e.target.files[0])
    }

    useEffect(() => {
        if (!image) {
            setPreview(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(image)
        setPreview(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [image])



  return (
    <div className='navDebug cardEdit'>
        <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
        <div className='panelHolder panelEdit'>
            <div className='smallPanel'>
                <div className='panelImgHolder'>
                    <img src={preview ? preview : card && url + card.card.image} className={card && card.card.mirrored ? "mirrored" : ""} alt="cardImg"/>
                </div>
                <h3>{title ? title : card && card.title}</h3>
            </div>
        </div>

        <div className='changeNotes'>
            <div>
                <h4>Image</h4>
                <p>only jpg/png allowed</p>
                <input onChange={(e)=>{onSelectFile(e)}} type="file"/>
            </div>
            <div>
                <h4>Title</h4>
                <p>Minimum 10 digits</p>
                <input onChange={(e)=>{setTitle(e.target.value)}} minLength="10" type="text"/>
            </div>
            <button onClick={handleEdit}>Edit</button>
        </div>
    </div>
  )
}

export default CardEdit