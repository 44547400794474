import React from 'react'
import { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import usePartners from './../../utils/hooks/usePartners'
import {baseUrlState} from './../../utils/atoms'
import './css.css'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { deletePartners } from '../../utils/api';

const Partners = () => {

    const [url] = useRecoilState(baseUrlState)
    const [update, setUpdate] = useState(true)
    const [partners] = usePartners(update);
    const navigate = useNavigate()
    
    const handleDelete = async(id) => {
        const response = await deletePartners(id)
        if(response.status === 204){
            setUpdate(!update)
            
        }
    }

    const renderPartners = useMemo(()=>{
        return partners && partners.map((elem, i)=>{            
            return(
                <div className='smallPanel' key={i}>
                    <div className='panelImgHolder'>
                        <img src={url + elem.logo} alt="partnerLogo"/>
                    </div>
                    <h3>{elem.title}</h3>
                    <div className='smallPanelControls'>
                        <button onClick={()=>{navigate(`/admin/partners/${elem.id}/edit`)}}>Edit</button>
                        <button onClick={()=>{handleDelete(elem.id)}}>Delete</button>
                    </div>
                </div>
            )
        })
    }, [partners, update])

  return (
    <div className='partners'>
        <h1>Partners Configurator</h1>
        <div className='panelHolder'>
            {renderPartners}
            <div className='smallPanel emptyPanel' onClick={()=>{navigate(`/admin/partners/add`)}}>
                <span>+</span>
            </div>
        </div>
    </div>
  )
}

export default Partners