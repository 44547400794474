import { useEffect, useState } from "react"
import { getCarousel } from "../api"
import { useAuth } from "../auth"

const useCarousel = (update) => {
    
    const [carousel, setCarousel] = useState()
    const auth = useAuth()

    const getData = async(setFunc) => {
        try{
        const response = await getCarousel()

        setFunc(response.data)
        }catch(err){
            console.log(err)
            if(err.response.status === 403 && err.response.data.detail === "Invalid token or expired token."){
                alert('Connection Timed Out')
                auth.logout()
            } 
        }
    }

    useEffect(()=>{
        getData(setCarousel) 

    }, [auth.user, update])
 
    return [carousel]  

}

export default useCarousel