import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { deleteService, editSolution } from '../../../utils/api'
import useCurrentSolution from './../../../utils/hooks/useCurrentSolution'
import useCurrentServices from './../../../utils/hooks/useCurrentServices'
import {ArrowBack, EditIcon} from './../../../assets/icons'
import './css.css'
import { useRecoilState } from 'recoil'
import {baseUrlState} from './../../../utils/atoms'

const SolutionEdit = () => {

  const {solutionId, sectorId} = useParams()
  const [currentSolution] = useCurrentSolution(solutionId)
  const navigate = useNavigate()
  const [update, setUpdate] = useState(false)
  const [services] = useCurrentServices(solutionId, update)
  const [url] = useRecoilState(baseUrlState)

  const [preview, setPreview] = useState()

  const [image, setImage] = useState(undefined)
  const [title, setTitle] = useState(undefined)
  const [short_description, setShortDescription] = useState(undefined)
  const [full_description, setFullDescription] = useState(undefined)

  const handleEdit = async() => {
    const form = new FormData()

    image && form.append('image', image)
    title && form.append('title', title)
    short_description && form.append('short_description', short_description)
    full_description && form.append('full_description', full_description)

    try{
      const response = await editSolution(solutionId, form)

      if(response.status === 202){
        toast.success('Created successfuly', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });
      }else{
        toast.error('Something went wrong', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });  
      }
    }catch(err){
      console.log(err)
      toast.error('Something went wrong', {
        position: "bottom-center",                                
        closeOnClick: true,                                
        progress: undefined,
        theme: "colored",
        });  
    }
  }

  const onSelectedFile = (e) => {
    if(!e.target.files || e.target.files.length === 0) {
      setImage(undefined)
      return
    }
    setImage(e.target.files[0])
  }

  useEffect(()=>{
    if(!image){
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(image)
    setPreview(objectUrl)

    return () => URL.revokeObjectURL(objectUrl) 
  }, [image])

  const handleDelete = async(id) =>{
    await deleteService(id)
    setUpdate(!update)
  }

  const renderServices = useMemo(()=>{
    return services && services.map((elem, i)=>{
      return(
        <li key={i}>
          {elem.title}
          <div className='editButtons'>
              <button onClick={()=>{navigate(`/admin/sectors/${sectorId}/solution/${solutionId}/service/${elem.id}/edit`)}}><EditIcon /></button>
              <button onClick={()=>{handleDelete(elem.id)}}>X</button>
            </div>
        </li>
      )
    })
  }, [services])

  return (
    <div className='navDebug solutionEdit'>
      <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
      <p>The preview below is just a rough example of how it will look on the site.</p>
      <div className='servicesEdit'>
        <div>
          <div>
            <h1>{title ? title : currentSolution && currentSolution.title}</h1>
          </div>
          <div>
            <p>{full_description ? full_description : currentSolution && currentSolution.full_description}</p>
          </div>
        </div>
        <div>
          <div className='servicesEditImage'>
            <div>
              <img src={preview ? preview : currentSolution && url + currentSolution.solution.image} alt="solutionImage"/>
            </div>
          </div>
          <div>
            <h3>{short_description ? short_description : currentSolution && currentSolution.short_description}</h3>
            <ul className='solutionEditServices'>
              {renderServices}
              <li className='emptyPanel emptyItemPanel' onClick={()=>{navigate(`/admin/sectors/${sectorId}/solution/${solutionId}/service/add`)}}><span>+</span></li>
            </ul>
          </div>

        </div>
      </div>

      <div className='changeNotes'>
        <div>
          <h4>Image</h4>
          <input onChange={(e)=>{setImage(e.target.files[0])}} type="file"/>
        </div>
        <div>
          <h4>Title</h4>
          <input onChange={(e)=>{setTitle(e.target.value)}} defaultValue={currentSolution && currentSolution.title} type="text"/>
        </div>
        <div>
          <h4>Services Description</h4>
          <input onChange={(e)=>{setShortDescription(e.target.value)}} defaultValue={currentSolution && currentSolution.short_description} type="text"/>
        </div>
        <div>
          <h4>Full Description</h4>
          <textarea onChange={(e)=>{setFullDescription(e.target.value)}} defaultValue={currentSolution && currentSolution.full_description} />
        </div>        
        <button onClick={handleEdit}>Edit</button>
      </div>
    </div>
  )
}

export default SolutionEdit