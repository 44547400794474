import React from 'react'
import { useState } from 'react'
import { useParams, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import {addSolution} from './../../../utils/api'
import {ArrowBack} from './../../../assets/icons'
import './css.css'

const SolutionAdd = () => {

  const {sectorId} = useParams()
  const navigate = useNavigate()
  const [image, setImage] = useState(undefined)
  const [title, setTitle] = useState(undefined)
  const [shortDescription, setShortDescription] = useState(undefined)
  const [fullDescription, setFullDescription] = useState(undefined)

  const handleCreate = async() => {
    const form = new FormData()

    form.append('sector_id', sectorId)
    form.append('title', title)
    form.append('image', image)
    form.append('short_description', shortDescription)
    form.append('full_description', fullDescription)

    try{
      const response = await addSolution(form)

      if(response.status === 201){
        toast.success('Created successfuly', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });
      }else{
        toast.error('Something went wrong', {
          position: "bottom-center",                                
          closeOnClick: true,                                
          progress: undefined,
          theme: "colored",
          });  
      }

    }catch(err){
      console.log(err)
      toast.error('Something went wrong', {
        position: "bottom-center",                                
        closeOnClick: true,                                
        progress: undefined,
        theme: "colored",
        });  
    }
  }

  return (
    <div className='navDebug solutionAdd'>
      <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
      <div className='changeNotes'>
        <div>
          <h4>Image</h4>
          <input type="file" onChange={(e)=>{setImage(e.target.files[0])}}/>
        </div>
        <div>
          <h4>Title</h4>
          <input type="text" onChange={(e)=>{setTitle(e.target.value)}}/>
        </div>
        <div>
          <h4>Services description</h4>
          <input type="text" onChange={(e)=>{setShortDescription(e.target.value)}}/>
        </div>
        <div>
          <h4>Main description</h4>
          <textarea onChange={(e)=>{setFullDescription(e.target.value)}} />
        </div>
        <button onClick={handleCreate}>Create</button>
      </div>
    </div>
  )
}

export default SolutionAdd