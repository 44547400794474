import React from 'react'
import Vacancy from './../../components/Vacancy'
import './css.css'

const Connect = () => {
  return (
    <div className='navDebug'>
        <Vacancy />
    </div>
  )
}

export default Connect