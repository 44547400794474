import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import {addCarousel} from './../../../utils/api'
import {ArrowBack} from './../../../assets/icons'
import './css.css'

const CarouselAdd = () => {
    const [description, setDescription] = useState(null)
    const [image, setImage] = useState(null)
    const navigate = useNavigate()

    const handleSend = async() => {
        const fd = new FormData()
        fd.append('title', description)
        fd.append('image', image)

        try{
          const response = await addCarousel(fd)
          
          if(response.status === 201) {
            toast.success('Created successfuly', {
              position: "bottom-center",                                
              closeOnClick: true,                                
              progress: undefined,
              theme: "colored",
              });
          }else{
            toast.error('Something went wrong', {
              position: "bottom-center",                                
              closeOnClick: true,                                
              progress: undefined,
              theme: "colored",
              });
          }

        }catch(err){
          console.log(err)
          toast.error('Something went wrong', {
            position: "bottom-center",                                
            closeOnClick: true,                                
            progress: undefined,
            theme: "colored",
            });
        }

    }

  return (
    <div className='navDebug carouselAdd'>
        <button className='goBackButton' onClick={()=>{navigate(-1)}}><ArrowBack /> Back</button>
        <div className='changeNotes'>
            <div>
              <h4>Image</h4>
              <input type="file" onChange={(e)=>{setImage(e.target.files[0])}}/>
            </div>
            <div>
              <h4>Description</h4>
              <textarea onChange={(e)=>{setDescription(e.target.value)}}/>
            </div>
            <button onClick={handleSend}>Send</button>
        </div>
    </div>
  )
}

export default CarouselAdd