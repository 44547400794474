import React from 'react'
import { useMemo } from 'react'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import useCards from './../../utils/hooks/useCards'
import {baseUrlState} from './../../utils/atoms'
import './css.css'
import { deleteCard } from '../../utils/api'
import { useNavigate } from 'react-router-dom'

const Cards = () => {

    const navigate = useNavigate()

    const [update, setUpdate] = useState()
    const [cards] = useCards(update)
    const [url] = useRecoilState(baseUrlState)

    
    const handleDelete = async(id) => {
        await deleteCard(id)
        setUpdate(!update)
    }


    const renderCards = useMemo(()=>{
        return cards && cards.map((elem, i)=>{            
            return(
            <div className='smallPanel' key={i}>
                <div className='panelImgHolder'>
                    <img src={url + elem.image} className={elem.mirrored ? "mirrored" : ""}/>
                </div>
                <h3>{elem.title}</h3>
                <div className='smallPanelControls'>
                    <button onClick={()=>{navigate(`/admin/cards/${elem.id}/edit`)}}>Edit</button>
                    <button onClick={()=>{handleDelete(elem.id)}}>Delete</button>
                </div>
            </div>
            )
        })
    }, [cards, update])

  return (
    <div className='cards'>
        <h1>Cards Configurator</h1>
        <div className='panelHolder'>
            {renderCards}
            <div className='smallPanel emptyPanel' onClick={()=>{navigate(`/admin/cards/add`)}}>
                <span>+</span>
            </div>
        </div>
    </div>
  )
}

export default Cards